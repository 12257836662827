import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { Link, StaticQuery, graphql } from 'gatsby'

const NotFoundPage = (props) => (
  <StaticQuery
    query={graphql`
      query {
        allSitePage {
          edges {
            node {
              path,
            }
          }
        }
      }
    `}
    render={data => {
      const pages = data.allSitePage.edges.filter(
        p => ! p.node.path.includes(404)
      )

      return (
        <Layout location={props.location}>
          <SEO title="404: Not found" />

          <div>
            <h1 className="mb-4">Page Not Found</h1>

            <p>Try one of the following links:</p>

            <ul>
              {pages.map(page => (
                <li key={page.node.path}>
                  <Link to={page.node.path}>{page.node.path}</Link>
                </li>
              ))}
            </ul>
          </div>
        </Layout>
      )
    }}
  />
)

export default NotFoundPage
